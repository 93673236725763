@use 'user-variables'; // Using variables of other scss file here, see for example border-radius below


/* ***** Browsers' Components ***** */

::-webkit-scrollbar {
  width: 0.8rem;
}
::-webkit-scrollbar-track {
  background: user-variables.$gray-100;
}
::-webkit-scrollbar-thumb {
  background-color: user-variables.$gray-500;
  border-radius: 20px;
  border: 2px solid user-variables.$gray-100;
}

.home-page::-webkit-scrollbar-track {
  background: user-variables.$gray-400;
}

.home-page::-webkit-scrollbar-thumb {
  background-color: user-variables.$gray-700;
  border-radius: 20px;
  border: 2px solid user-variables.$gray-400;
}


/* ***** Page packgrounds ***** */

$safera-bg-gradient: linear-gradient(180deg, #00426C, #3992CB);

.home-page {
  background-image: $safera-bg-gradient;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  min-height: 100%;
  background-repeat: no-repeat;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}
.home-page::-webkit-scrollbar {
  display: none;
}

.device-settings-page {
  background-image: $safera-bg-gradient;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  height: 100%;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  box-shadow:inset 0 0 0 2000px rgba(0, 0, 0, 0.3); // TESTI onko tämä UX-ystävällinen ratkaisu? Vähän kuin popup, mutta ei kuitenkaan?
}

html {
  height: 100%;
}
body {
  min-height: 100%;
}


/* ***** Settings pages ***** */

$settings-max-width: 850px;
$safera-navbar-settings-gradient: linear-gradient(#00426C, #025B95);
$settings-hero-gradient: linear-gradient(white, #b8c4ca);
$settings-navbar-height: 50px;
$settings-hero-height: 180px;
$settings-hero-height-desktop: 130px;
$settings-items-column-height-sm-md: 80px;
$tabs-items-settings-max-width: 35%;

.settings-navbar-left-column {
  min-width: 120px !important;
}

.settings-container {
  max-width: $settings-max-width !important;
}

.nav-bar-settings-page {
  height: $settings-navbar-height !important;
  background-image: $safera-navbar-settings-gradient;
  background-size: contain;
}

.hero-settings-page {
  height: $settings-hero-height !important;
}

.settings-hero-first-column {
  min-width: 2rem;
}

.hero-background-gradient {
  background-image: $settings-hero-gradient;
  background-size: 100% calc(#{$settings-navbar-height} + #{$settings-hero-height} + #{$settings-items-column-height-sm-md}); // Needs to be as high as Navbar+Hero+Tabs(Mobile)
  background-repeat: no-repeat;
}

.tab-items-settings-page::-webkit-scrollbar { // Hide scrollbar for Chrome, Safari and Opera
  display: none;
}

.tab-items-settings-page { // Hide scrollbar for IE, Edge and Firefox
  -ms-overflow-style: none;  /* IE and Edge */
  /* scrollbar-width: none;  /* Firefox */
}

.tab-content-settings-page {
  background: user-variables.$gray-200;
}


@media (max-width: 576px) {

  .settings-page-container {
    overflow: visible !important;
  }

  .row-tabs-system-container {
    height: 100% !important;
  }

  .tab-content-settings-page {
    overflow: visible !important;
    min-height: 100%;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

}

@media (max-width: 767px) {

  .settings-page-container {
    overflow: auto;
    max-height: calc(100% - 1rem) !important;
    border-bottom-left-radius: user-variables.$border-radius;
    border-bottom-right-radius: user-variables.$border-radius;
  }

  .settings-page-container::-webkit-scrollbar { // Hide scrollbar for Chrome, Safari and Opera
    display: none;
  }

  .settings-page-container { // Hide scrollbar for IE, Edge and Firefox
    -ms-overflow-style: none;  /* IE and Edge */
    /* scrollbar-width: none;  /* Firefox */
  }

  .hero-image.sense-wifi {
    background: url("../img/Safera-Sense-Wi-Fi-front-transparent-1000x371.png");
    background-position-y: 115px;
    background-position-x: 20px;
    background-size: 520px;
    background-repeat: no-repeat;
  }

  .hero-image.sense-pro {
    background: url("../img/Safera-Sense-Pro-front-transparent-1000x371.png");
    background-position-y: 115px;
    background-position-x: 20px;
    background-size: 520px;
    background-repeat: no-repeat;
  }
    
  .hero-image.sense-integral {
    background: url("../img/Sense-Integral-transparent-1000x695.png");
    background-position-y: 50px;
    background-position-x: 40px;
    background-size: 530px;
    background-repeat: no-repeat;
  }

  .hero-image.sense-unknown {
    background: url("../img/unknown-device-1000x371.png");
    background-position-y: 50px;
    background-position-x: 40px;
    background-size: 530px;
    background-repeat: no-repeat;
  }
    
  .tab-content-settings-page {
    overflow: visible !important;
    min-height: 100% !important;
    border-bottom-left-radius: user-variables.$border-radius;
    border-bottom-right-radius: user-variables.$border-radius;
  }

  .row-tabs-system-container {
    height: 100% !important;
  }

  .tabbable {
    box-sizing: border-box;
    height: 100%;
  }

  .tabbable .nav-pills { // Horizontal scrolling for tabs
    overflow-x: auto;
    overflow-y: hidden;
    flex-wrap: nowrap;
  }

  .tabbable .nav-pills .nav-link {
    white-space: nowrap;
  }

  .tab-items-settings-page {
    height: $settings-items-column-height-sm-md;
  }
  
  .tab-items-settings-page.nav {

    a.active.nav-link {
      background-color: user-variables.$primary !important;
      box-shadow: user-variables.$box-shadow !important;
    }

    a.nav-link {
      background-color: white !important;
      box-shadow: user-variables.$box-shadow !important;
    }

  }

}

@media (min-width: 768px) {

  .settings-page-container {
    overflow: hidden !important;
    height: 100% !important;
  }

  .hero-settings-page {
    height: $settings-hero-height-desktop !important;
  }

  .hero-image.sense-wifi {
    background: url("../img/Safera-Sense-Wi-Fi-front-transparent-1000x371.png");
    background-position-y: 55px;
    background-position-x: center;
    background-size: 450px;
    background-repeat: no-repeat;
  }

  .hero-image.sense-pro {
    background: url("../img/Safera-Sense-Pro-front-transparent-1000x371.png");
    background-position-y: 55px;
    background-position-x: center;
    background-size: 450px;
    background-repeat: no-repeat;
  }

  .hero-image.sense-integral {
    background: url("../img/Sense-Integral-transparent-1000x695.png");
    background-position-y: 25px;
    background-position-x: right;
    background-size: 530px;
    background-repeat: no-repeat;
  }

  .tab-content-settings-page {
    overflow-y: scroll !important;
    overflow-x: hidden !important;
    border-bottom-right-radius: user-variables.$border-radius !important;
  }

  .tab-content-settings-page::-webkit-scrollbar-track {
    border-bottom-right-radius: user-variables.$border-radius !important;
  }

  .tab-items-settings-page {
    border-right: solid 1px white;
    border-bottom-left-radius: user-variables.$border-radius;
    max-width: $tabs-items-settings-max-width;
    background: user-variables.$gray-100;
  }

  .nav-item:hover {
    border-radius: user-variables.$border-radius;
    background-color: user-variables.$gray-200; // white;
  }
    
  .row-tabs-system-container {
    height: calc(100% - #{$settings-hero-height-desktop} - #{$settings-navbar-height}) !important;
    background-color: user-variables.$gray-100;
    border-bottom-left-radius: user-variables.$border-radius;
    border-bottom-right-radius: user-variables.$border-radius;
  }

  .tabbable {
    overflow: hidden !important;
    border-bottom-right-radius: user-variables.$border-radius !important;
  }

  .tab-items-settings-page.nav {

    a.active.nav-link {
      background-color: user-variables.$primary !important;
    }

  }

}


/* ***** Styling for fixed Headings on home page ***** */


.home-page .navbar {
  margin-bottom: 8rem;
}

.fixed-headings {
  position: fixed;
  top: 7rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 0;
}


/* ***** Styling for Cards and Panels ***** */

.panel-item-heading-with-link {
  line-height: 1.3rem !important; // To fix aligning when heading and basic text are in the same row
}

.panel-item-small-heading-with-link {
  line-height: 1.0rem !important; // To fix aligning when heading and basic text are in the same row
}


/* Device Cards */

$card-device-max-width: 320px;
$card-device-min-width: 260px;

.card.device {
  max-width: $card-device-max-width;
  min-width: $card-device-min-width;
  width: 100%;
  border-radius: user-variables.$border-radius;
  background: none !important;
}

.card.device .card-body {
  height: 180px;
  border-radius: user-variables.$border-radius;
}

.card.device .icon {
  width: 100%;
}

.device-cards-container {
  display: grid !important;
	grid-template-columns: repeat(auto-fit, minmax($card-device-min-width, $card-device-max-width));
}

.device-cards-name {
  line-height: 1rem !important;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.shared-device:before {
  content: "";
  position: absolute;
  left: 0; right: 0;
  top: 0; bottom: 0;
  background: $safera-bg-gradient;
  opacity: 0.75;
  border-radius: user-variables.$border-radius;
}

/* Product Variations */

.card.device .card-body-sense-wifi {
  background: url("../img/Device-Card-Bg-Sense.png") center / cover no-repeat;
}

.card.device .card-body-sense-pro {
  background: url("../img/Device-Card-Bg-Sense-Pro.png") center / cover no-repeat;
}

.card.device .card-body-sense-integral {
  background: url("../img/Device-Card-Bg-Integral.png") center / cover no-repeat;
}


/* Date Range Picker */

.date-range-picker {
  background: #fff;
  cursor: pointer;
  padding: 5px 10px;
  box-shadow: user-variables.$box-shadow;
  border-radius: user-variables.$border-radius;
  width: 100%;
  text-align: center;
}


/* ***** Icons ***** */

.icon-grease-filter {
  background-image: url("../img/icon-grease-filter.svg");
  display: inline-block;
  height: 100%; // 25px;
  width: 1.3rem; // 25px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.dropdown-menu-icon {
  width: 1rem;
  margin-right: 0.8rem;
  overflow: visible;
  justify-content: center;
  font-size: 1.1rem;
  line-height: 1rem;
}


/* ***** Buttons ***** */

.btn-circle-with-icon {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 1.25rem !important;
  font-size: 0.875rem !important;
}


/* ***** Tabs in panels ***** */

.tabs-panel .nav {

  a.active.nav-link {
    background: none !important;
  }

}

.tabs-panel .nav-tabs {
  background: user-variables.$gray-100; // linear-gradient(180deg, #ffffff, #f2f6fa);
  border-top-left-radius: user-variables.$border-radius;
  border-top-right-radius: user-variables.$border-radius;
  overflow-x: auto !important;
  overflow-y: hidden !important;
  border: none !important;
}

.tabs-panel {
  background: user-variables.$gray-100;
}

.tabs-panel .nav-item:hover {
  background: none !important;
}

.tabs-panel .nav-item {
  background: none !important;
}

.tabs-panel .nav-tabs::-webkit-scrollbar { // Hide scrollbar for Chrome, Safari and Opera
  display: none;
}

.tabs-panel .nav-tabs { // Hide scrollbar for IE, Edge and Firefox
  -ms-overflow-style: none;  /* IE and Edge */
  /* scrollbar-width: none;  /* Firefox */
}

.tabs-panel .nav-tabs .nav-link {
font-weight: 600;
}



/* ***** Wizard ***** */

$wizard-mobile-break: 450px;
$wizard-header-height: 50px;
$wizard-card-height: 80px;

.wizard-body {
  overflow: auto;
  height: auto !important;
  padding-bottom: 1rem;
}

.wizard-header {
//  max-width: $wizard-mobile-break !important;
  height: $wizard-header-height;
  background-repeat: no-repeat;
}

.wizard-header-content {
  max-width: $wizard-mobile-break !important;
  background-image: $safera-navbar-settings-gradient;
}

.wizard-div-above-container {
  height: calc(100% - #{$wizard-header-height}) !important;
  border-bottom-left-radius: user-variables.$border-radius;
  border-bottom-right-radius: user-variables.$border-radius;
}

.wizard-container {
  min-height: 100vh !important;
  height: auto;
  overflow: hidden;
  max-width: $wizard-mobile-break !important;
  border-bottom-left-radius: user-variables.$border-radius;
  border-bottom-right-radius: user-variables.$border-radius;
  background-color: user-variables.$gray-100;
}

.wizard-content {
  height: 100%;
}

.wizard-upper-content {
  min-height: auto;
}

.wizard-lower-content {
  min-height: auto;
}

.card-user-account {
  height: $wizard-card-height !important;
  width: 100%;
  border-radius: user-variables.$border-radius;
  background-color: white !important;
}

.card.wizard-device {
  width: 100%;
  border-radius: user-variables.$border-radius;
  background: none !important;
}

.card-body-sense-wifi.card-device-body-wizard {
  height: $wizard-card-height !important;
  width: 100%;
  border-radius: user-variables.$border-radius;
  background: url("../img/Device-Card-Wizard-Bg-Sense.png") center / cover no-repeat !important;
}

.card-body-sense-pro.card-device-body-wizard {
  height: $wizard-card-height !important;
  border-radius: user-variables.$border-radius;
  background: url("../img/Device-Card-Wizard-Bg-Sense-Pro.png") center / cover no-repeat !important;
}

.card-body-sense-integral.card-device-body-wizard {
  height: $wizard-card-height !important;
  border-radius: user-variables.$border-radius;
  background: url("../img/Device-Card-Wizard-Bg-Integral.png") center / cover no-repeat !important;
}

@media (max-width: $wizard-mobile-break) {

  .wizard-body {
    padding-bottom: 0;
  }
  .wizard-container {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .wizard-div-above-container {
    overflow: auto;
    max-height: 100% !important;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  
}

.wizard-icon {
  height: 20px;
  width: 20px;
  overflow: hidden;
}

/* Wizard Settings Container */

.wizard-hero-image.sense-wifi {
  background: url("../img/Safera-Sense-Wi-Fi-front-transparent-1000x371.png");
  background-position-y: 65px;
  background-position-x: 20px;
  background-size: 520px;
  background-repeat: no-repeat;
}
  
.wizard-hero-image.sense-integral {
  background: url("../img/Sense-Integral-transparent-1000x695.png");
  background-position-y: 10px;
  background-position-x: 40px;
  background-size: 530px;
  background-repeat: no-repeat;
}

.wizard-settings-page-container {
  max-height: calc(100% - 1rem) !important;
  overflow: hidden !important;
}

.wizard-settings-page-container::-webkit-scrollbar { // Hide scrollbar for Chrome, Safari and Opera
  display: none;
}

.wizard-settings-page-container { // Hide scrollbar for IE, Edge and Firefox
  -ms-overflow-style: none;  /* IE and Edge */
  /* scrollbar-width: none;  /* Firefox */
}

.wizard-tab-content-settings-page {
  min-height: 100% !important;
  border-bottom-left-radius: user-variables.$border-radius;
  border-bottom-right-radius: user-variables.$border-radius;
}

.wizard-row-tabs-system-container {
  height: 100% !important;
}

.wizard-tabbable {
  box-sizing: border-box;
  height: 100%;
}

.wizard-tabbable .nav-pills { // Horizontal scrolling for tabs
  overflow-x: auto;
  overflow-y: hidden;
  flex-wrap: nowrap;
}

.wizard-tabbable .nav-pills .nav-link {
  white-space: nowrap;
}

.wizard-tab-items-settings-page {
  height: auto;
  // display: none !important; // Jos halutaan piilottaa tabit Wizardista, sen voi tehdä tällä, mutta kasvata sitten hero-alueen kokoa +80px
}

.wizard-tab-items-settings-page.nav {

  a.active.nav-link {
    background-color: user-variables.$primary !important;
    box-shadow: user-variables.$box-shadow !important;
  }

  a.nav-link {
    background-color: white !important;
    box-shadow: user-variables.$box-shadow !important;
  }

}

.wizard-tab-items-settings-page::-webkit-scrollbar { // Hide scrollbar for Chrome, Safari and Opera
  display: none;
}

.wizard-tab-items-settings-page { // Hide scrollbar for IE, Edge and Firefox
  -ms-overflow-style: none;  /* IE and Edge */
  /* scrollbar-width: none;  /* Firefox */
}

.wizard-tab-content-settings-page {
  background: user-variables.$gray-200;
}

.wizard-hero-settings-page {
  height: $settings-hero-height !important;
}


/* ***** Log in panel ***** */

.log-in-panel {
  max-width: 530px;
}

.log-in-welcome-panel {
  min-height: 350px;
}

.log-in-logo {
  max-width: 100px;
}

.horizontal-divider {
  display: none;
}

.log-in-continue-with-panel {
  border-top-right-radius: user-variables.$border-radius;
  border-bottom-right-radius: user-variables.$border-radius;
  background-color: user-variables.$gray-200;
}

@media (max-width: 575px) {

  .log-in-container {
    padding: 0 25px 0 25px !important;
  }

  .log-in-panel {
    max-width: 350px;
    box-shadow: none !important;
  }  

  .log-in-welcome-panel {
    min-height: auto;
  }
  
  .log-in-continue-with-panel{
    border-radius: none;
    border-bottom-left-radius: user-variables.$border-radius;
    border-bottom-right-radius: user-variables.$border-radius;
    background-color: user-variables.$gray-100;
  }

  .horizontal-divider {
    display: block;
    border-top: 1px solid user-variables.$gray-400;
  }
  
  
}


/* Other */

.deviceTitlefadeOut{
  opacity:0;
  transition:  opacity 0.5s ease;
}
.deviceTitlefadeIn{
  opacity:1;
  transition:  opacity 0.5s 0.5s ease;
}